/*
*****************************************************在这里配置路由*****************************************************
*/ 
import { createRouter, createWebHistory } from 'vue-router';
import Login from '../components/LoginVue.vue'; // 登录页面
import Management from '../components/MangeVue.vue'; // 登录页面
import IndexVue from '../components/IndexVue.vue'; // 登录页面


const routes = [

  

  {
    path: '/login',
    name: 'Login',
    component: Login
  },  
  {
    path: '/',
    name: 'IndexVue',
    component: IndexVue
  },
  {
    path:'/add',
    name:'add',
    component:Management,
  },
]; 

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

// router.beforeEach((to, from, next) => {
//   const isLoginRequired = to.matched.some(record => record.meta.requiresAuth)
//   const isUserLoggedIn = localStorage.getItem('userToken') // 假设用userToken作为登录标识
 
//   if (isLoginRequired && !isUserLoggedIn) {
//     next('/h/login') // 未登录则重定向到登录页面
//   } else {
//     next() // 已登录，或者不需要登录的页面，正常导航
//   }
// })
export default router;
