<template>
  <div>
    <el-card class="search-card">
      <h2 class="title">恶意买家查询</h2>
      <el-row :gutter="20" align="middle">
        <el-col :span="14" :md="16" :lg="18">
          <el-input v-model="account" placeholder="输入账号" class="input-account"></el-input>
        </el-col>
        <el-col :span="10" :md="8" :lg="6">
          <el-row>
            <el-col :span="11">
              <el-button type="primary" @click="search" class="btn-search">搜索</el-button>
            </el-col>
            <el-col :span="12">
              <el-button type="success" @click="goToAddPage" class="btn-submit">提交</el-button>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <div v-if="result && Object.keys(result).length > 0" class="result-section">
        <el-card class="result-card">
          <div class="result-item account">
            <span class="result-label account-label">账号:</span>
            <span class="result-value">{{ result.account }}</span>
          </div>
          <div class="result-item platform">
            <span class="result-label platform-label">平台:</span>
            <span class="result-value">{{ result.platform }}</span>
          </div>
          <div class="result-item eventCategory">
            <span class="result-label eventCategory-label">事件分类:</span>
            <span class="result-value">{{ result.eventCategory }}</span>
          </div>
          <div class="result-item region">
            <span class="result-label region-label">地区:</span>
            <span class="result-value">{{ result.region }}</span>
          </div>
        </el-card>
      </div>
      <div v-else-if="searched" class="no-result-section">
        <el-alert title="未该用户查出黑历史，请放心" type="info" show-icon></el-alert>
      </div>
      <el-divider></el-divider>
      <p class="intro">
        <span class="part1">本平台可以查询恶意买家（差评师、仅退款、素质差、爱差评、难伺候各种类型买家）。</span><br>
        <span class="part2">也希望各位买家积极提交恶意买家信息，共同维护商家利益。</span><br>
        <span class="part3">有更好的建议可以加微信：dsours</span>
      </p>
      <img src="qrcod.png" alt="微信二维码" class="wechat-qr">
    </el-card>
  </div>
</template>

<script>
import { ref } from 'vue';
import axios from 'axios';
import { useRouter } from 'vue-router'; // 导入 Vue Router

export default {
  setup() {
    const account = ref('');
    const result = ref(null);
    const searched = ref(false);
    const router = useRouter(); // 使用 Vue Router

    const search = async () => {
      try {
        const response = await axios.get(`/api/search?account=${account.value}`);
        if (response.data && Object.keys(response.data).length > 0) {
          result.value = response.data;
        } else {
          result.value = null;
        }
        searched.value = true;
      } catch (error) {
        console.error(error);
        result.value = null;
        searched.value = true;
      }
    };

    // 跳转到 /add 页面
    const goToAddPage = () => {
      router.push('/add');
    };

    return {
      account,
      result,
      searched,
      search,
      goToAddPage, // 返回跳转方法
    };
  },
};
</script>

<style scoped>
.search-card {
  max-width: 600px;
  margin: auto;
  padding: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.title {
  color: #67C23A;
  text-align: center;
  font-size: 24px;
}

.intro {
  font-size: 16px;
  line-height: 1.5;
}

.part1 {
  color: #409EFF; /* 第一段颜色 */
}

.part2 {
  color: #F56C6C; /* 第二段颜色 */
}

.part3 {
  color: #67C23A; /* 第三段颜色 */
}

.wechat-qr {
  display: block;
  margin: 10px auto;
  width: 150px;
  height: 150px;
  border-radius: 15px; /* 添加圆角 */
}

.input-account {
  width: 100%; /* 让输入框占满父容器 */
  height: 39px; /* 调整高度 */
  font-size: 14px; /* 调整字体大小 */
}

.btn-search,
.btn-submit {
  width: 100%; /* 让按钮占满父容器 */
  height: 39px; /* 调整按钮高度 */
  font-size: 16px; /* 调整按钮字体大小 */
}

.result-section {
  margin-top: 20px;
}

.result-card {
  padding: 15px;
  background-color: #f9f9f9; /* 背景颜色 */
  border-radius: 8px; /* 圆角 */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* 阴影效果 */
}

.result-item {
  display: flex;
  justify-content: space-between; /* 在两侧对齐 */
  margin-bottom: 10px; /* 结果项之间的间距 */
}

.result-label {
  font-weight: bold; /* 加粗标签 */
}

/* 定义不同的颜色 */
.account .result-label,
.account .result-value {
  color: #409EFF; /* 蓝色 */
}

.platform .result-label,
.platform .result-value {
  color: #67C23A; /* 绿色 */
}

.eventCategory .result-label,
.eventCategory .result-value {
  color: #F56C6C; /* 红色 */
}

.region .result-label,
.region .result-value {
  color: #FF9800; /* 橙色 */
}

.no-result-section {
  margin-top: 20px;
}
</style>
