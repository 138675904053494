<template>
    <div class="login_container">
      <div class="login_box">
        <!-- 头像区域 -->
        <div class="avatar_box">
          <img src="../assets/logo.png" alt="" />
        </div>
        <!-- 登录表单区域 -->
        <el-form ref="loginFormRef" :model="loginForm" :rules="loginFormRules" label-width="auto" class="login_form">
          <!-- 用户名 -->
          <el-form-item label="用户名" prop="username">
            <el-input
              v-model="loginForm.username"
            >
            </el-input>
          </el-form-item>
          <!-- 密码 -->
          <el-form-item label="密码" prop="password">
            <el-input
              v-model="loginForm.password" type="password"
              show-password
            >
            </el-input>
          </el-form-item>
          <!-- 按钮区域 -->
          <el-row justify="end">
            <el-form-item class="login_btn">
              <!-- 没有账号，点击注册 -->
              <el-button type="primary" @click="Login">登录</el-button>
            </el-form-item>
          </el-row>
        </el-form>
      </div>
    </div>
  </template>
   
<script>
import { ElMessage } from 'element-plus'
import axios from 'axios';
  export default {
    data () {
        return {
        // 这是登录表单的数据绑定对象
        loginForm: {
            username: '',
            password: ''
        },
        // 这是表单的验证规则对象
        loginFormRules: {
            // 验证用户名是否合法
            username: [
            { required: true, message: '请输入登录名称', trigger: 'blur' },
            { min: 1, max: 10, message: '长度在 1 到 10 个字符', trigger: 'blur' }
            ],
            // 验证密码是否合法
            password: [
            { required: true, message: '请输入登录密码', trigger: 'blur' },
            { min: 1, max: 15, message: '长度在 1到 15 个字符', trigger: 'blur' }
            ]
        }
        }
    },
    methods: {
        Login () {
          this.$refs.loginFormRef.validate((valid) => {
            if(valid){
                console.log(this)
                console.log(this.loginForm)
                // 发送请求，返回登录状态码
                axios.post('/api/user_login',{
                  username:this.loginForm.username,
                  password:this.loginForm.password
                }).then(res=>{
                  if(res.data.meta.status==200){
                    ElMessage({
                      message: '登录成功',
                      type: 'success',
                      plain: true,
                    })
                    if(res.data.data.is_admin == 1){ // 管理员登录后跳转到后台
                      this.$router.push('/h/management');

                    }else{ // 普通用户登录后跳转主页面
                      this.$router.push('/h');

                    }
                    localStorage.setItem('userToken', this.loginForm.username)  //本地存储身份验证
                    localStorage.setItem('isAdmin', res.data.data.is_admin)  //存储是否管理员
                    // localStorage.setItem('userToken', res.data.data)  //本地存储身份验证

                  }else{
                    ElMessage({
                      message: '登录失败',
                      type: 'error',
                      plain: true,
                    })
                  }
                  
                }).catch(err=>{
                  console.log(111,err)
                })
            }else{
              console.log('验证失败')
            }
          })
        }
      }
    }
</script>
   
<style scoped>
    #app{
      margin-top:0px;
      padding: 0px;
      
    }
    .login_container {
      margin:0px;
      padding: 0px;
      background-color: #2b4b6b;
      /* 00245a */
      height: 100vh;
    }

    .login_box {
    width: 450px;
    height: 300px;
    background-color: #fff;
    border-radius: 3px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    }

    .login_box .avatar_box {
    height: 130px;
    width: 130px;
    border: 1px solid #eee;
    border-radius: 50%;
    padding: 10px;
    box-shadow: 0 0 10px #ddd;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    }

    .login_box .avatar_box img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #eee;
    }

    .login_form {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 0 50px;
    box-sizing: border-box;
    }

    .login_btn {
    display: flex;
    justify-content: flex-end;
    }
</style>