import { createApp } from 'vue';
import App from './App.vue';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import './element-variables.scss'; // 引入自定义主题
import router from './router'; // 导入路由配置
import { createPinia } from 'pinia';// 组件之间传变量
import locale from 'element-plus/es/locale/lang/zh-cn'; // 引入中文语言包
import * as Icons from '@element-plus/icons-vue'; 



const app = createApp(App);

Object.keys(Icons).forEach(key => {  
  app.component(key, Icons[key]);  
}); 

app.use(ElementPlus, { locale });
app.use(router); // 使用路由器


const debounce = (fn, delay) => {
    let timer = null;
    return function () {
        let context = this;
        let args = arguments;
        clearTimeout(timer);
        timer = setTimeout(function () {
        fn.apply(context, args);
        }, delay);
    };
};
  

const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
  constructor(callback) {
    callback = debounce(callback);
    super(callback);
  }
};


// 组件之间传变量
const pinia = createPinia();
app.use(pinia);



// app.mount('#app');放在最后
app.mount('#app');