<template>
  <div>
    <el-card>
      <h2 style="color: #409EFF;">提交恶意买家信息</h2>
      <el-divider></el-divider>
      <el-form @submit.prevent="addAccount" :model="newAccount" label-width="80px">
        <el-form-item label="账号">
          <el-input v-model="newAccount.account" placeholder="请输入账号" required></el-input>
        </el-form-item>
        <el-form-item label="平台">
          <el-select v-model="newAccount.platform" placeholder="请选择平台" filterable>
            <el-option label="拼多多" value="拼多多"></el-option>
            <el-option label="淘宝" value="淘宝"></el-option>
            <el-option label="京东" value="京东"></el-option>
            <el-option label="抖音商城" value="抖音商城"></el-option>
            <el-option label="唯品会" value="唯品会"></el-option>
            <el-option label="其他" value="其他"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="事件分类">
          <el-select v-model="newAccount.eventCategory" placeholder="请选择事件分类" filterable>
            <el-option label="仅退款" value="仅退款"></el-option>
            <el-option label="差评师" value="差评师"></el-option>
            <el-option label="爱差评" value="爱差评"></el-option>
            <el-option label="素质差" value="素质差"></el-option>
            <el-option label="难伺候" value="难伺候"></el-option>
            <el-option label="其他" value="其他"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="地区">
          <el-select v-model="newAccount.region" placeholder="请选择地区" filterable>
            <el-option label="北京市" value="北京市"></el-option>
            <el-option label="上海市" value="上海市"></el-option>
            <el-option label="天津市" value="天津市"></el-option>
            <el-option label="重庆市" value="重庆市"></el-option>
            <el-option label="黑龙江省" value="黑龙江省"></el-option>
            <el-option label="吉林省" value="吉林省"></el-option>
            <el-option label="辽宁省" value="辽宁省"></el-option>
            <el-option label="河北省" value="河北省"></el-option>
            <el-option label="河南省" value="河南省"></el-option>
            <el-option label="山东省" value="山东省"></el-option>
            <el-option label="山西省" value="山西省"></el-option>
            <el-option label="安徽省" value="安徽省"></el-option>
            <el-option label="江西省" value="江西省"></el-option>
            <el-option label="江苏省" value="江苏省"></el-option>
            <el-option label="浙江省" value="浙江省"></el-option>
            <el-option label="福建省" value="福建省"></el-option>
            <el-option label="台湾省" value="台湾省"></el-option>
            <el-option label="海南省" value="海南省"></el-option>
            <el-option label="广东省" value="广东省"></el-option>
            <el-option label="湖南省" value="湖南省"></el-option>
            <el-option label="湖北省" value="湖北省"></el-option>
            <el-option label="四川省" value="四川省"></el-option>
            <el-option label="贵州省" value="贵州省"></el-option>
            <el-option label="云南省" value="云南省"></el-option>
            <el-option label="陕西省" value="陕西省"></el-option>
            <el-option label="甘肃省" value="甘肃省"></el-option>
            <el-option label="青海省" value="青海省"></el-option>
            <el-option label="新疆维吾尔自治区" value="新疆维吾尔自治区"></el-option>
            <el-option label="内蒙古自治区" value="内蒙古自治区"></el-option>
            <el-option label="宁夏回族自治区" value="宁夏回族自治区"></el-option>
            <el-option label="广西壮族自治区" value="广西壮族自治区"></el-option>
            <el-option label="西藏自治区" value="西藏自治区"></el-option>
            <el-option label="香港特别行政区" value="香港特别行政区"></el-option>
            <el-option label="澳门特别行政区" value="澳门特别行政区"></el-option>
            <!-- 添加更多省份 -->
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="addAccount">添加账号</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import { ref } from 'vue';
import axios from 'axios';

export default {
  setup() {
    const newAccount = ref({
      account: '',
      platform: '拼多多',
      eventCategory: '',
      region: '',
    });

    const addAccount = async () => {
      try {
        const response = await axios.post('/api/add', newAccount.value);
        alert(response.data.message);
        newAccount.value = { account: '', platform: '拼多多', eventCategory: '', region: '' };
      } catch (error) {
        console.error(error);
        alert('添加失败');
      }
    };

    return {
      newAccount,
      addAccount,
    };
  },
};
</script>

<style>
el-input, el-select {
    font-size: 16px; /* 确保字体大小符合条件 */
}
input, textarea, select {
    touch-action: manipulation; /* 禁用触控放大 */
}
</style>
